<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        class="log-form"
      >
        <el-form-item>
          <el-button
            type="primary"
            @click="handleAddAccount"
          >
            添加账号
          </el-button>
          <el-button
            type="primary"
            @click="openBatchUpload"
          >
            批量添加
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <el-table
      v-loading="accountListInfo.loading"
      :data="accountListInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="accountId"
        label="账号ID"
        min-width="100"
      />
      <el-table-column
        prop="accountName"
        label="账号"
        min-width="100"
      />
      <el-table-column
        prop="password"
        label="账号密码"
        min-width="100"
      />
      <el-table-column
        prop="description"
        label="账号描述"
        min-width="100"
      />
      <el-table-column
        label="状态"
        min-width="100"
      >
        <template v-slot="{row}">
          {{ computedAccountStatus(row) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="150"
      />
      <el-table-column
        label="操作"
        min-width="120"
      >
        <template v-slot="{row}">
          <BTextButton
            title="编辑"
            icon="fa fa-pencil"
            @click="handleEditAccount(row)"
          />
          <BTextButton
            :loading="row.loading"
            :title="row.enable === 1 ? '启用' : '禁用'"
            :icon="`fa ${row.enable === 1 ? 'fa-check-circle' : 'fa-ban' }`"
            @click="handleToggleAccount(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="accountListInfo.total"
      :page.sync="formData.page"
      :limit.sync="formData.size"
      @pagination="queryAccountList(formData)"
    />

    <!--    新增或者编辑-->
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleSubFormClose"
    >
      <el-form
        ref="subForm"
        :model="subFormData"
        :rules="subFormDataRules"
        label-width="100px"
        class="subForm"
      >
        <el-form-item
          label="账号"
          prop="accountName"
        >
          <el-input
            v-model="subFormData.accountName"
            type="text"
            placeholder="单行输入"
          />
        </el-form-item>
        <el-form-item
          label="密码"
          prop="password"
        >
          <el-input
            v-model="subFormData.password"
            type="text"
            placeholder="单行输入"
          />
        </el-form-item>
        <el-form-item
          label="描述"
          prop="description"
        >
          <el-input
            v-model="subFormData.description"
            type="text"
            placeholder="单行输入"
          />
        </el-form-item>
        <el-form-item
          label="状态"
          prop="enable"
        >
          <el-switch
            v-model="subFormData.enable"
            :active-value="1"
            :inactive-value="2"
            active-text="启用"
            inactive-text="停用"
          />
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <div class="ctrl-area">
          <el-button
            :loading="updateOrCreateLoading"
            type="primary"
            @click="handleUpdateOrCreateAccount"
          >
            保存
          </el-button>
          <el-button @click="handleDialogClose">
            取消
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!--    批量上传-->
    <el-dialog
      :visible.sync="batchUploadVisible"
      width="500px"
      @closed="loadedList = []"
    >
      <div class="batchUpload">
        <div class="icon-wrapper">
          <i class="el-icon-upload upload-icon" />
        </div>
        <template v-if="!loadedList.length">
          <div class="batchUpload-tips">
            <uploadExcel
              text="上传账号，仅支持excel"
              @uploaded="handleUploadExcel"
            />
          </div>
          <div>
            <el-button
              type="text"
              @click="downloadExcel"
            >
              下载模板
            </el-button>
          </div>
        </template>
        <template v-else>
          <p>读取到{{ loadedList.length }}个账户，是否确认上传</p>
          <div class="batchUpload-ctrl-area">
            <uploadExcel
              text="重新上传"
              @uploaded="handleUploadExcel"
            />
            <el-button
              type="primary"
              @click="handlePostExcelData"
            >
              确定
            </el-button>
          </div>
        </template>
      </div>
    </el-dialog>
  </Layout>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { downloadExcel } from '@/components/UploadExcel/downloadExcel'
import uploadExcel from '@/components/UploadExcel/uploadExcel'
import { updateOrCreateAccount, bulkAccountAdd } from '@/api/rentAccount'

const initSubFormData = () => ({
  accountId: 0,
  groupId: '',
  accountName: '',
  password: '',
  description: '',
  enable: 1
})
export default {
  name: 'RentAccountManageNoCache',
  components: { uploadExcel },
  data () {
    return {
      formData: {
        page: 1,
        size: 20
      },
      dialogVisible: false,
      batchUploadVisible: false,
      loadedList: [],
      subFormData: initSubFormData(),
      subFormDataRules: {
        accountName: [{ required: true }],
        password: [{ required: true }]
      },
      updateOrCreateLoading: false,
      batchUploadTemplate: {
        list: [],
        header: ['账号(必填)', '密码(必填)', '描述(非必填)', '状态(必填, 1启用, 2停用)'],
        fields: ['accountName', 'password', 'description', 'enable']
      }
    }
  },
  computed: {
    ...mapState('rentAccount', ['accountListInfo'])
  },
  created () {
    this.formData.groupId = this.$route.query.groupId
    this.queryAccountList(this.formData)
  },
  methods: {
    ...mapActions('rentAccount', ['queryAccountList']),
    computedAccountStatus (row) {
      if (row.enable === 2) return '已禁用'
      if (row.useStatus === 1) return '空闲中'
      return '使用中'
    },
    downloadExcel () {
      const header = this.batchUploadTemplate.header
      const list = this.batchUploadTemplate.list
      const fields = this.batchUploadTemplate.fields
      downloadExcel(list, header, fields, 'template')
    },
    uploadExcel (data) {
      const header = this.batchUploadTemplate.header
      const fields = this.batchUploadTemplate.fields
      const maps = header.reduce((result, field, index) => {
        result[field] = fields[index]
        return result
      }, {})
      return data.list.map(item => {
        const temp = {}
        Object.keys(item).forEach(field => {
          temp[maps[field]] = item[field]
        })
        return temp
      })
    },
    handleUploadExcel (data) {
      this.loadedList = this.uploadExcel(data)
      this.loadedList.forEach(item => {
        item.groupId = this.formData.groupId
      })
      // check list
    },
    handlePostExcelData () {
      const list = this.loadedList
      bulkAccountAdd({ list })
        .then(res => {
          if (res.code === 200) {
            this.batchUploadVisible = false
            this.queryAccountList(this.formData)
          }
        })
    },
    handleUpdateOrCreateAccount () {
      this.$refs.subForm.validate().then(valid => {
        if (valid) {
          this.updateOrCreateLoading = true
          this.subFormData.groupId = this.formData.groupId
          updateOrCreateAccount(this.subFormData)
            .then(res => {
              if (res.code === 200) {
                this.dialogVisible = false
                this.queryAccountList(this.formData)
              }
            })
            .finally(() => {
              this.updateOrCreateLoading = false
            })
        }
      })
    },
    openBatchUpload () {
      this.batchUploadVisible = true
    },
    handleAddAccount () {
      this.subFormData = initSubFormData()
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.subForm.clearValidate()
      })
    },
    handleEditAccount (row) {
      this.subFormData = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
      this.$nextTick().then(() => {
        this.$refs.subForm.clearValidate()
      })
    },
    handleToggleAccount (row) {
      const {
        enable,
        useStatus
      } = row
      if (enable === 1) {
        if (useStatus !== 1) {
          this.$confirm('当前账号正在使用中，无法禁用，请稍后重试', '提示', {
            type: 'warning ',
            confirmButtonText: '好的',
            showCancelButton: false
          })
          return false
        }
        this.updateOrCreateAccountConfirm(row, 2)
      } else {
        this.updateOrCreateAccountConfirm(row, 1)
      }
    },
    updateOrCreateAccountConfirm (row, enable) {
      const tips = enable === 2 ? '此操作将禁用改账号， 是否继续?' : '此操作将启用该账号， 是否继续?'
      this.$confirm(tips, '提示', { type: 'warning ' })
        .then(() => {
          const {
            accountId,
            groupId,
            accountName,
            password,
            description
          } = row
          this.$set(row, 'loading', true)
          updateOrCreateAccount({
            groupId,
            accountId,
            accountName,
            password,
            description,
            enable
          })
            .then(res => {
              if (res.code === 200) {
                this.$set(row, 'enable', enable)
              }
            }).finally(() => {
              this.$set(row, 'loading', false)
            })
        })
    },
    // 取消弹窗 二次确认
    dialogCloseConfirm () {
      return this.$confirm('确认取消吗', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
    },
    handleDialogClose () {
      this.dialogCloseConfirm()
        .then(() => {
          this.dialogVisible = false
        })
    },
    handleSubFormClose (done) {
      this.dialogCloseConfirm()
        .then(() => {
          done()
        })
    }
  }
}
</script>

<style scoped lang="less">
  .subForm{
    .el-form-item{
      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }
  .ctrl-area{
    text-align: center;
    .el-button{
      padding: 10px 40px;
    }
  }
  .batchUpload{
    text-align: center;
    .icon-wrapper{
      .upload-icon{
        font-size: 100px;
        color: @primary;
      }
    }
    .batchUpload-tips{
      margin: 20px 0 10px;
    }
    .batchUpload-ctrl-area{
      margin-top: 30px;
      text-align: right;
    }
  }
</style>
